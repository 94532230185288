import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import moment from 'moment-timezone';

import { createSlots } from '../../../components/order/Availabilities';
import { getSlots } from '../orderReducer';
import OrderContainer from '../../common/OrderContainer';
import Step4 from '../../../components/Step4v1';

const formatSlots = (slots) => {
  const formattedSlots = [];
  Object.keys(slots).forEach((slotDay) => {
    slots[slotDay].slotBlocks.forEach((slotBlock) => {
      slotBlock.slots.filter(slot => slot.isActive)
      .forEach((slot) => {
        const day = moment(moment(slotDay, 'D M YY').format());
        const begin = moment(day.set({ hours: slot.hours, minutes: slot.minutes }).format());
        const end = moment(begin.clone().add(30, 'minute').format());
        formattedSlots.push({ begin, end });
      });
    });
  });
  return formattedSlots.sort((slotA, slotB) => slotA.begin.valueOf() - slotB.begin.valueOf());
};

const createDay = currentDay => (
  {
    allDay: { isActive: false, text: 'Toute la journée' },
    date: moment(currentDay),
    slotBlocks: [
      { isActive: false, text: 'Toute la matinée', slots: createSlots(9, 13, false) },
      { isActive: false, text: "Tout l'après-midi", slots: createSlots(14, 17, false) },
      { isActive: false, text: 'Toute la soirée', slots: createSlots(18, 21) },
    ],
  }
);

const getSlotIndex = (beginHour, beginMinute) => {
  const is30 = beginMinute === 30;
  const blocks = [
    { begin: 9, end: 14 },
    { begin: 14, end: 18 },
    { begin: 18, end: 21.30 },
  ];
  let blockIndex = 2;
  if (beginHour < blocks[1].end) blockIndex = 1;
  if (beginHour < blocks[0].end) blockIndex = 0;

  let slotIndex = (beginHour - blocks[blockIndex].begin) * 2;
  if (is30) slotIndex += 1;
  return { blockIndex, slotIndex };
};

const unFormatSlots = (slots) => {
  const unFormattedSlots = {};
  const blocksNbSlots = [10, 8, 7];
  const blockActiveSlotsCount = {};
  slots.forEach((slot) => {
    const day = slot.begin.format('D M YY');
    const beginHour = slot.begin.hour();
    const beginMinute = slot.begin.minute();
    const currentDay = (moment('D M YY') === day) ? moment() : moment(day, 'D M YY');
    unFormattedSlots[day] = unFormattedSlots[day] || createDay(currentDay);
    const { blockIndex, slotIndex } = getSlotIndex(beginHour, beginMinute);
    blockActiveSlotsCount[day] = blockActiveSlotsCount[day] || [0, 0, 0];
    blockActiveSlotsCount[day][blockIndex] += 1;
    unFormattedSlots[day].slotBlocks[blockIndex].slots[slotIndex].isActive = true;
  });
  slots.forEach((slot) => {
    const day = slot.begin.format('D M YY');
    let nbBlocksActive = 0;
    [0, 1, 2].forEach((index) => {
      if (blockActiveSlotsCount[day][index] === blocksNbSlots[index]) {
        unFormattedSlots[day].slotBlocks[index].isActive = true;
        nbBlocksActive += 1;
      }
    });
    if (nbBlocksActive === 3) unFormattedSlots[day].allDay.isActive = true;
  });
  return unFormattedSlots;
};

class RdvContainer extends React.Component {
  constructor() {
    super();
    this.state = { slots: {} };
    this.goNext = this.goNext.bind(this);
    this.selectDay = this.selectDay.bind(this);
  }

  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
  }

  dispatchSelectedDates(selectedDates) {
    this.props.dispatch({ type: 'ORDER.SET_SLOTS', slots: formatSlots(selectedDates) });
  }

  goNext() {
    navigate(this.props.nextStep);
  }

  selectDay(selectedDay) {
    this.setState({
      selectedDay: selectedDay.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    });
  }

  validateDay(newSlots) {
    const { selectedDay, slots } = this.state;
    this.setState({
      selectedDay: null,
      slots: { ...slots, [selectedDay.format()]: newSlots } });
  }

  render() {
    return (
      <OrderContainer>
        <Step4
          goNext={() => this.goNext()}
          dispatchSelectedDates={selectedDates => this.dispatchSelectedDates(selectedDates)}
          slots={unFormatSlots(this.props.slots)}
        />
      </OrderContainer>
    );
  }
}

RdvContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  nextStep: PropTypes.string.isRequired,
  slots: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = state => ({
  slots: getSlots(state),
});

const mapDispatchToProps = dispatch => ({ dispatch });
export default connect(mapStateToProps, mapDispatchToProps)(RdvContainer);
