import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import styled from 'styled-components';
import Availabilities from './order/Availabilities';
import { Wrapper, Block, Logo, Header2 } from './styledComponents';
import ProgressBar from './order/ProgressBar';
import LogoImg from '../assets/logo1-128.png';
import { trackEvent } from '../services/analytics';

const StyledBlock = styled(Block)`
  max-width: 1000px;
  padding: 30px 70px;
`;

const Step4 = ({ slots, goNext, dispatchSelectedDates }) => {
  const orderContext = {
    slots,
    goNext() { goNext(); },
    dispatchSelectedDates(selectedDates) { dispatchSelectedDates(selectedDates); },
  };
  return (
    <Wrapper>
      <StyledBlock>
        <Header2>
          <Link
            to={'/'} style={{ flex: 1, textAlign: 'center' }}
            onClick={() => trackEvent('click', 'home_step4')}
          >
            <Logo src={LogoImg} />
          </Link>
          <ProgressBar step={3} />
        </Header2>
        <Availabilities orderContext={orderContext} />
      </StyledBlock>
    </Wrapper>
  );
};

Step4.propTypes = {
  goNext: PropTypes.func.isRequired,
  dispatchSelectedDates: PropTypes.func.isRequired,
  slots: PropTypes.shape({}).isRequired,
};

export default Step4;
